import {SmartLink} from '@mfb/lego';
import * as React from 'react';

import {MetadataContentModel} from '../contentful/metadata/MetadataContentModel';
import PageTemplate from '../templates/PageTemplate';

const notFoundMetadata: MetadataContentModel = {
  title: 'Page Not Found',
  description: {
    description: 'Sorry, this page could not be found',
  },
  type: 'article',
  canonicalUrlLink: {
    relativePath: '/404',
  },
  sharingLinkImage: {
    id: '',
    __typename: '',
    alternateText: '',
    small: {
      id: '',
      file: {
        url: '',
      },
    },
    large: {
      id: '',
      file: {
        url: '',
      },
    },
  },
  keywords: [],
  allowIndexing: false,
};

const NotFound: React.FunctionComponent = () => (
  <PageTemplate
    metadata={notFoundMetadata}
    render={() => (
      <div className="container py-5">
        <h1 className="pb-2">Page not found!</h1>
        <p>
          {"We're"} sorry, the page {"doesn't"} exist or may no longer be
          available. Please check the URL for correct spelling.
        </p>
        <p>
          If that {"doesn't"} work, try the home page by clicking the button
          below.
        </p>

        <SmartLink href="/" className="btn btn-primary">
          Home
        </SmartLink>
      </div>
    )}
  />
);

export default NotFound;
